import React, { useEffect, useState, useMemo } from "react";
import { graphql } from "gatsby";
import { concat, uniq, compact, flatten } from "lodash-es";
import { isBefore, isAfter } from "date-fns";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/layout/Layout";
import SalesQuestion from "../components/SalesQuestion";
import { WebinarVideosAndEventsSection, LoadMoreButton, getEventGTMViewItem } from "../components/Event";

import green_bg from "../assets/images/green_bg.jpg";
import spotify_banner from "../assets/images/spotify_banner.png";
import apple_banner from "../assets/images/apple_podi_banner.png";
import google_banner from "../assets/images/google_banner.png";
import rss from "../assets/images/rss.png";

import { pushToGTM, pushSAEvent } from "../helpers";

const ALL_FILTER_OPTION = 'all';

export default ({ data, location }) => {
    const sortedWebinars = data.allContentfulWebinarV2.edges.sort((a, b) => new Date(b.node.webinarStartTime) - new Date(a.node.webinarStartTime));
    const sortedVideos = data.allContentfulVideo.edges.sort((a, b) => new Date(b.node.createdAt) - new Date(a.node.createdAt));
    const sortedEvents = data.allContentfulEvent.edges.sort((a, b) => new Date(b.node.startTime) - new Date(a.node.startTime));
    const sortedPodcasts = data.allContentfulPodcast.edges.sort((a, b) => new Date(b.node.createdAt) - new Date(a.node.createdAt));

    // common filters stuff start
    const getFilterOptionsForProp = (propMapper, flattenPropValues) => {
        const mappedPropValues = concat([], sortedWebinars, sortedVideos, sortedEvents, sortedPodcasts).map((v) => propMapper(v));
        const flatPropValues = uniq(compact(flattenPropValues ? flatten(mappedPropValues) : mappedPropValues));

        return [
            {
                label: ALL_FILTER_OPTION,
                value: ALL_FILTER_OPTION,
                checked: true,
            },
            ...flatPropValues.map((v) => {
                return {
                    label: v,
                    value: v,
                    checked: false,
                }
            }),
        ];
    };

    const handleFilterChange = (curState, value, checked) => {
        const nextState = curState.map((opt) => {
            if (value === ALL_FILTER_OPTION && checked && opt.value !== ALL_FILTER_OPTION) {
                return {
                    ...opt,
                    checked: false,
                }
            }
            if (value !== ALL_FILTER_OPTION && checked && opt.value === ALL_FILTER_OPTION) {
                return {
                    ...opt,
                    checked: false,
                }
            }
            if (opt.value === value) {
                return {
                    ...opt,
                    checked,
                }
            }
            return opt;
        });

        const anyChecked = nextState.find((opt) => opt.checked);
        if (!anyChecked) {
            return nextState.map((opt) => {
                if (opt.value === ALL_FILTER_OPTION) {
                    return {
                        ...opt,
                        checked: true,
                    }
                }
                return opt;
            });
        }
        return nextState;
    };

    const doesFilterContainAnyValue = (filterOptions, values) => {
        if (!filterOptions || !values) {
            return false;
        }
        const lowerCasedValues = values.map((v) => String(v).toLowerCase());
        return !!filterOptions.find((opt) => lowerCasedValues.includes(String(opt.value).toLowerCase()) && opt.checked);
    };
    // common filters stuff end

    // keyword filters start
    const getInitialKeywordFilterOptions = () => {
        return getFilterOptionsForProp((v) => {
            if (!v.node.keywords) {
                return undefined;
            }
            return v.node.keywords.map((kw) => String(kw).toLowerCase());
        }, true);
    };

    const [keywordFilterOptions, setKeywordFilterOptions] = useState(getInitialKeywordFilterOptions);

    const keywordFiltersConfig = {
        title: 'keywords',
        options: keywordFilterOptions,
        onChange: (value, checked) => setKeywordFilterOptions((curState) => handleFilterChange(curState, value, checked)),
    };
    // keyword filters end

    // language filters start
    const getInitialLanguageFilterOptions = () => {
        return getFilterOptionsForProp((v) => String(v.node.webinarLanguage ? v.node.webinarLanguage : v.node.language).toLowerCase());
    };

    const [languageFilterOptions, setLanguageFilterOptions] = useState(getInitialLanguageFilterOptions());

    const languageFiltersConfig = {
        title: 'language',
        options: languageFilterOptions,
        onChange: (value, checked) => setLanguageFilterOptions((curState) => handleFilterChange(curState, value, checked)),
    };
    // language filters end

    // category filters start
    const CATEGORY_FILTER_WEBINAR = 'webinar';
    const CATEGORY_FILTER_VIDEO = 'product video';
    const CATEGORY_FILTER_EVENT = 'event';
    const CATEGORY_FILTER_PODCAST = 'podcast';

    const getInitialCategoryFilterOptions = () => {
        return [
            {
                label: ALL_FILTER_OPTION,
                value: ALL_FILTER_OPTION,
                checked: true,
            },
            ...[CATEGORY_FILTER_WEBINAR,
                CATEGORY_FILTER_VIDEO,
                CATEGORY_FILTER_EVENT,
                CATEGORY_FILTER_PODCAST
            ].map((v) => {
                return {
                    label: v,
                    value: v,
                    checked: false,
                }
            }),
        ];
    };

    const [categoryFilterOptions, setCategoryFilterOptions] = useState(getInitialCategoryFilterOptions());

    const categoryFiltersConfig = {
        title: 'category',
        options: categoryFilterOptions,
        onChange: (value, checked) => setCategoryFilterOptions((curState) => handleFilterChange(curState, value, checked)),
    };
    // category filters end

    const handleClearAllFilters = () => {
        setKeywordFilterOptions(getInitialKeywordFilterOptions());
        setLanguageFilterOptions(getInitialLanguageFilterOptions());
        setCategoryFilterOptions(getInitialCategoryFilterOptions());
    };

    // webinars and videos start
    const getFilteredWebinarVideosAndEvents = (timeFilter) => {
        let filteredWebinars = sortedWebinars.filter((f) => timeFilter(f.node.webinarEndTime));
        let filteredVideos = sortedVideos.filter((f) => timeFilter(f.node.createdAt));
        let filteredEvents = sortedEvents.filter((f) => timeFilter(f.node.endTime));

        if (!doesFilterContainAnyValue(keywordFilterOptions, [ALL_FILTER_OPTION])) {
            filteredWebinars = filteredWebinars.filter((v) => doesFilterContainAnyValue(keywordFilterOptions, v.node.webinarKeywords));
            filteredVideos = filteredVideos.filter((v) => doesFilterContainAnyValue(keywordFilterOptions, v.node.keywords));
            filteredEvents = filteredEvents.filter((v) => doesFilterContainAnyValue(keywordFilterOptions, v.node.keywords));
        }
        if (!doesFilterContainAnyValue(languageFilterOptions, [ALL_FILTER_OPTION])) {
            filteredWebinars = filteredWebinars.filter((v) => doesFilterContainAnyValue(languageFilterOptions, [v.node.webinarLanguage]));;
            filteredVideos = filteredVideos.filter((v) => doesFilterContainAnyValue(languageFilterOptions, [v.node.language]));
            filteredEvents = filteredEvents.filter((v) => doesFilterContainAnyValue(languageFilterOptions, [v.node.language]));;
        }
        
        let webinarVideosAndEvents = [];

        if (doesFilterContainAnyValue(categoryFilterOptions, [ALL_FILTER_OPTION]) || doesFilterContainAnyValue(categoryFilterOptions, [CATEGORY_FILTER_WEBINAR])) {
            webinarVideosAndEvents = [...filteredWebinars];
        }
        if (doesFilterContainAnyValue(categoryFilterOptions, [ALL_FILTER_OPTION]) || doesFilterContainAnyValue(categoryFilterOptions, [CATEGORY_FILTER_VIDEO])) {
            webinarVideosAndEvents = [...webinarVideosAndEvents, ...filteredVideos];
        }
        if (doesFilterContainAnyValue(categoryFilterOptions, [ALL_FILTER_OPTION]) || doesFilterContainAnyValue(categoryFilterOptions, [CATEGORY_FILTER_EVENT])) {
            webinarVideosAndEvents = [...webinarVideosAndEvents, ...filteredEvents];
        }

        webinarVideosAndEvents.sort((a, b) => {
            const bTime = new Date(b.node.webinarStartTime || b.node.startTime || b.node.createdAt);
            const aTime = new Date(a.node.webinarStartTime || a.node.startTime || a.node.createdAt);
            return bTime - aTime;
        });

        return webinarVideosAndEvents;
    };

    const [numberOfShownUpcomingItems, setNumberOfShownUpcomingItems] = useState(6);

    const shownUpcomingItems = useMemo(() => {
        const filteredUpcomingItems = getFilteredWebinarVideosAndEvents((d) => isBefore(new Date(), new Date(d)));
        return {
            items: filteredUpcomingItems.slice(0, numberOfShownUpcomingItems),
            numberOfTotalItems: filteredUpcomingItems.length,
        };
    }, [keywordFilterOptions, languageFilterOptions, categoryFilterOptions, numberOfShownUpcomingItems]);

    const [numberOfShownLibraryItems, setNumberOfShownLibraryItems] = useState(6);

    const shownLibraryItems = useMemo(() => {
        const filteredLibraryItems = getFilteredWebinarVideosAndEvents((d) => isAfter(new Date(), new Date(d)));
        return {
            items: filteredLibraryItems.slice(0, numberOfShownLibraryItems),
            numberOfTotalItems: filteredLibraryItems.length,
        };
    }, [keywordFilterOptions, languageFilterOptions, categoryFilterOptions, numberOfShownLibraryItems]);
    // webinars and videos end

    // podcasts start
    const [numberOfShownPodcastItems, setNumberOfShownPodcastItems] = useState(4);

    const getFilteredPodcasts = () => {
        let filteredPodcast = sortedPodcasts;

        if (!doesFilterContainAnyValue(keywordFilterOptions, [ALL_FILTER_OPTION])) {
            filteredPodcast = filteredPodcast.filter((v) => doesFilterContainAnyValue(keywordFilterOptions, v.node.keywords));
        }
        if (!doesFilterContainAnyValue(languageFilterOptions, [ALL_FILTER_OPTION])) {
            filteredPodcast = filteredPodcast.filter((v) => doesFilterContainAnyValue(languageFilterOptions, [v.node.language]));
        }
        if (doesFilterContainAnyValue(categoryFilterOptions, [ALL_FILTER_OPTION, CATEGORY_FILTER_PODCAST])) {
            return filteredPodcast;
        }
        return [];
    };

    const shownPodcastItems = useMemo(() => {
        const filteredPodcasts = getFilteredPodcasts();
        return {
            items: filteredPodcasts.slice(0, numberOfShownPodcastItems),
            numberOfTotalItems: filteredPodcasts.length,
        };
    }, [keywordFilterOptions, languageFilterOptions, categoryFilterOptions, numberOfShownPodcastItems]);
    // podcasts end

    // START GTM ITEM EVENTS SETUP
    useEffect(() => {
        pushSAEvent("visit_webinars");
    }, []);

    React.useEffect(() => {
        const items = [...shownUpcomingItems.items, ...shownLibraryItems.items].map((w) => getEventGTMViewItem(w.node.title));

        pushToGTM({
            'event': 'view_item_list',
            'item_list_name': 'webinars',
            'items': items,
        });
    }, [shownUpcomingItems, shownLibraryItems])
    // END GTM ITEM EVENTS SETUP 

    return (
        <Layout
            title="Webinars, Videos & Events"
            transparentNavigation={true}
            pageContentId="media-library-content"
            seoData={{ image: green_bg }}
            location={location}
        >
            <main
                id="media-library-content"
                style={{ marginTop: "-5rem" }}
            >
                <HeroSection
                    coverImage={data.bg}
                    titlePart1="Webinars"
                    titlePart2="Videos & Events"
                    description={'Learn how to take the most out of Integrations and APIs from our extensive business video library in English, Finnish and Swedish. For the tech how-to tutorials check <a target="_blank" href="https://docs.frends.com/en/collections/1533101-webinars">Documentation</a> and <a target="_blank" href="/services/frends-academy">Academy</a>.'}
                />

                <ControlsSection
                    controlGroups={[
                        keywordFiltersConfig,
                        languageFiltersConfig,
                        categoryFiltersConfig,
                    ]}
                    onClearAllButtonClick={handleClearAllFilters}
                />

                {shownUpcomingItems.items.length > 0 && (
                    <WebinarVideosAndEventsSection
                        title="upcoming"
                        items={shownUpcomingItems.items}
                        numberOfTotalItems={shownUpcomingItems.numberOfTotalItems}
                        onLoadMoreButtonClick={() => setNumberOfShownUpcomingItems((cur) => cur + 6)}
                    />
                )}

                {shownLibraryItems.items.length > 0 && (
                    <WebinarVideosAndEventsSection
                        title="library"
                        items={shownLibraryItems.items}
                        numberOfTotalItems={shownLibraryItems.numberOfTotalItems}
                        onLoadMoreButtonClick={() => setNumberOfShownLibraryItems((cur) => cur + 6)}
                    />
                )}

                {shownPodcastItems.items.length > 0 && (
                    <PodcastsSection
                        items={shownPodcastItems.items}
                        numberOfTotalItems={shownPodcastItems.numberOfTotalItems}
                        onLoadMoreButtonClick={() => setNumberOfShownPodcastItems((cur) => cur + 4)}
                    />
                )}

                {(
                    shownUpcomingItems.items.length === 0
                    && shownLibraryItems.items.length === 0
                    && shownPodcastItems.items.length === 0
                ) && (
                        <NoResultsSection />
                    )}

                <SalesQuestion
                    color="orange"
                    email="juha@frends.com"
                    name="Juha Moisio"
                    tel="+358 40 739 1412"
                    title="Global Partners"
                    isGrey={true}
                />
            </main>
        </Layout>
    );
}

const HeroSection = ({
    coverImage,
    titlePart1,
    titlePart2,
    description
}) => (
    <section className="hero is-fullheight">
        <GatsbyImage
            image={getImage(coverImage)}
            alt=""
            style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                left: "0"
            }}
        />
        <div className="hero-body">
            <div
                className="container has-text-centered"
                style={{ maxWidth: '800px' }}
            >
                <h1 className="title size-100 is-w margin-bottom-0">
                    <span className="is-white font-variable"
                        style={{ lineHeight: '1' }}
                    >
                        {titlePart1}
                    </span>
                    <br />
                    <span className="is-white font-bold is-transparent text-stroke-white"
                        style={{ lineHeight: '1' }}
                    >
                        {titlePart2}
                    </span>
                </h1>
                <div className="is-flex is-justify-content-center margin-top-40 is-half is-offset-one-quarter">
                    <p className="is-white font-regular size-22 margin-bottom-10"
                        style={{
                            maxWidth: '550px',
                            lineHeight: '1.75'
                        }}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </div>
            </div>
        </div>
    </section>
);

const ControlsSection = ({ controlGroups, onClearAllButtonClick }) => {
    const [open, setOpen] = useState(false);

    const handleOpenButtonClick = () => setOpen((curState) => !curState);

    const handleClearAllButtonClick = () => onClearAllButtonClick();

    const numberOfCheckedOptions = useMemo(() => {
        const allOptions = flatten(controlGroups.map((cg) => cg.options));
        return allOptions.filter((opt) => opt.value !== ALL_FILTER_OPTION && opt.checked).length;
    }, [controlGroups]);

    const cssClasses = useMemo(() => {
        return `show-controls-mobile-buttons ${open && 'is-open'} ${numberOfCheckedOptions !== 0 && 'has-checked-filters'}`;
    }, [open, numberOfCheckedOptions]);

    useEffect(() => {
        const handleWindowResize = () => {
            if (1023 < window.innerWidth && !open) {
                setOpen(true);
            }
        };

        handleWindowResize();

        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return (
        <section
            className="section bg-grey-1"
            style={{
                paddingTop: '6.25rem',
                paddingBottom: '2.5rem'
            }}
        >
            <div className="container">
                <div className={`is-hidden-desktop is-flex is-justify-content-center is-align-items-center show-controls-mobile-buttons ${cssClasses}`}>
                    <button onClick={handleOpenButtonClick} >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 18H3V16H21V18ZM21 13H3V11H21V13ZM21 8H3V6H21V8Z" fill="#58696C" />
                        </svg>

                        <span>
                            Filters
                        </span>

                        {numberOfCheckedOptions !== 0 && (
                            <span>
                                {`(${numberOfCheckedOptions})`}
                            </span>
                        )}
                    </button>

                    <button onClick={handleClearAllButtonClick}>
                        Clear all
                    </button>
                </div>

                {open && (
                    <form className="is-flex columns is-flex-wrap-wrap">
                        {controlGroups.map((group, i) => (
                            <div
                                className="column is-full-mobile is-one-third-tablet"
                                key={i}
                            >
                                <ControlRadioButtonGroup
                                    title={group.title}
                                    options={group.options}
                                    onChange={group.onChange}
                                />
                            </div>
                        ))}
                    </form>
                )}
            </div>
        </section>
    );
};

const ControlRadioButtonGroup = ({
    title,
    options,
    onChange,
}) => {
    const handleInputValueChange = (e) => {
        onChange(e.currentTarget.value, e.currentTarget.checked);
    };

    return (
        <fieldset className="control-radio-button-group">
            <legend>
                {title}
            </legend>
            <div className="radio-buttons">
                {options.map((opt, i) => (
                    <div className="radio-button" key={i}>
                        <label>
                            <input
                                type="checkbox"
                                value={opt.value}
                                checked={opt.checked}
                                onChange={handleInputValueChange}
                            />
                            <span>
                                {opt.label}
                            </span>
                        </label>
                    </div>
                ))}
            </div>
        </fieldset>
    );
};

const PodcastsSection = ({
    items,
    numberOfTotalItems,
    onLoadMoreButtonClick,
}) => {
    return (
        <section
            className="section bg-white podcasts-section"
            style={{
                paddingTop: '3.313rem',
                paddingBottom: '3.563rem',
            }}
        >
            <div className="container is-flex is-flex-direction-column is-align-items-center">
                <h2 className="title">
                    Frends in Podcasts
                </h2>

                <h3
                    className="has-text-centered title"
                    style={{ marginTop: '3.313rem' }}
                >
                    Discover our very own podcast Integraatiofrendit & Our Featured Guest Episodes
                </h3>

                <ul
                    className="is-flex is-justify-content-center is-align-items-center is-flex-wrap-wrap"
                    style={{
                        marginTop: '4.063rem',
                        gap: '2.5rem',
                    }}
                >
                    <li>
                        <a
                            href="https://open.spotify.com/show/1OMZcRLsbWqqrpzb1B4qmt?si=8037f88813714411&nd=1"
                            target="_blank"
                            title="Listen to Integraatiofrendit podcast on Spotify"
                        >
                            <img
                                src={spotify_banner}
                                alt=""
                                loading="lazy"
                                style={{
                                    width: '19.625rem',
                                    height: '5.438rem'
                                }}
                            />
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://podcasts.apple.com/fi/podcast/integraatiofrendit/id1613122354"
                            target="_blank"
                            title="Listen to Integraatiofrendit podcast on Apple Podcasts"
                        >
                            <img
                                src={apple_banner}
                                alt=""
                                loading="lazy"
                                style={{
                                    width: '21.938rem',
                                    height: '8.375rem',
                                    marginBottom: '-1rem',
                                }}
                            />
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy84NDUwNDZiYy9wb2RjYXN0L3Jzcw"
                            target="_blank"
                            title="Listen to Integraatiofrendit podcast on Google Podcasts"
                        >
                            <img
                                src={google_banner}
                                alt=""
                                loading="lazy"
                                style={{
                                    width: '17.438rem',
                                    height: '6rem'
                                }}
                            />
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://anchor.fm/s/845046bc/podcast/rss"
                            target="_blank"
                            title="Subscribe to Integraatiofrendit using RSS"
                        >
                            <img
                                src={rss}
                                alt=""
                                loading="lazy"
                                style={{
                                    width: '5rem',
                                    height: '5rem'
                                }}
                            />
                        </a>
                    </li>
                </ul>

                <ul
                    className="is-flex is-flex-direction-row is-justify-content-center is-flex-wrap-wrap"
                    style={{
                        marginTop: '4.063rem',
                        columnGap: '6.25rem',
                        rowGap: '3.75rem',
                    }}
                >
                    {items.map((item, i) => (
                        <li key={i}>
                            <iframe
                                key={i}
                                src={item.node.spotifyEmbedUrl}
                                allowFullScreen=""
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                title={`Listen to Integraatiofrendit podcast episode ${item.node.title} on Spotify Podcast player`}
                                className="podcast-embed-iframe"
                            />
                        </li>
                    ))}
                </ul>

                {items.length < numberOfTotalItems && (
                    <LoadMoreButton onClick={onLoadMoreButtonClick} />
                )}
            </div>
        </section>
    );
};

const NoResultsSection = () => {
    return (
        <section className="section bg-grey-1">
            <div className="container">
                <p className="has-text-centered size-20">
                    Oops! No matching content yet. Try different filters
                </p>
            </div>
        </section>
    );
};

export const query = graphql`query MediaLibraryPageQuery {
    bg: file(relativePath: {eq: "images/green_bg.jpg"}) {
        childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
    }

    allContentfulWebinarV2(filter: {node_locale: {eq: "en-US"}}) {
        edges {
            node {
                title
                text
                webinarUrl
                webinarStartTime
                webinarLanguage
                webinarKeywords
                webinarEndTime
                webinarSpeakers {
                    name
                    title
                    portrait {
                        gatsbyImageData(width: 90, height: 90, quality: 100)
                    }
                }
                webinarImage {
                    gatsbyImageData(width: 800, quality: 100)
                }
            }
        }
    }

    allContentfulEvent(filter: {title: {ne: "dummy"}, node_locale: {eq: "en-US"}}) {
        edges {
            node {
                title
                text
                url
                startTime
                endTime
                location
                language
                keywords
                speakers {
                    name
                    title
                    portrait {
                        gatsbyImageData(width: 90, height: 90, quality: 100)
                    }
                }
                image {
                    gatsbyImageData(width: 800, quality: 100)
                }
            }
        }
    }

    allContentfulVideo(filter: {node_locale: {eq: "en-US"}}) {
        edges {
            node {
                title
                slug
                description
                keywords
                language
                createdAt
                image {
                    gatsbyImageData(width: 800, quality: 100)
                }
            }
        }
    }

    allContentfulPodcast(filter: {node_locale: {eq: "en-US"}}) {
        edges {
            node {
                title
                keywords
                language
                createdAt
                spotifyEmbedUrl
            }
        }
    }
}
`;